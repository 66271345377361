import React, { useEffect, useState } from "react"
import { observer } from "mobx-react";
import { Box, Button, CircularProgress, Divider, FormControl, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import Layout from "../../components/shared-layout";
import useStores from "../../hooks/use-stores";
import { ApiDataListType, ApiDataResponseListLengthCheck, GetCollegeId, PAGE_SIZE } from "../../constants/options";
import VmButton from "../../components/shared-button";
import VmTable from "../../components/shared-table";
import VmModal from "../../components/shared-modal";
import Breadcrumb from "../../components/shared-breadcrumb";
import { toJS } from "mobx";
import { t } from "i18next";
import ContentLayout from "../../components/shared-content-layout";
import { TD_FIRST, TD_LAST, TD_NORMAL } from "../../constants/style";

const CalendarIndexPage = observer(() => {
  const { rootStore, academicStore, calendarStore } = useStores();
  // Lists
  const [semesterList, setSemesterList] = useState<any>([]);
  const [termList, setTermList] = useState<any>([]);
  const [termDateList, setTermDateList] = useState<any>([]);
  const [currentYearId, setCurrentYearId] = useState<number>(0);
  const [currentSemesterId, setCurrentSemesterId] = useState<number>(0);
  const [currentTermId, setCurrentTermId] = useState<number>(0);
  const [currentTermDateId, setCurrentTermDateId] = useState<number>(0);
  // Modals
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showSemesterModal, setShowSemesterModal] = useState<boolean>(false);
  const [showTermModal, setShowTermModal] = useState<boolean>(false);
  const [showTermDateModal, setShowTermDateModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [deleteType, setDeleteType] = useState<string>("");
  // Determine is update or create
  const [targetYear, setTargetYear] = useState<any>();
  const [yearStartDate, setYearStartDate] = useState<string>("");
  const [yearEndDate, setYearEndDate] = useState<string>("");
  const [yearDescription, setYearDescription] = useState<string>("");
  const [targetSemester, setTargetSemester] = useState<any>();
  const [semesterDescription, setSemesterDescription] = useState<string>("");
  const [semesterInt, setSemesterInt] = useState<string>("0");
  const [targetTerm, setTargetTerm] = useState<any>();
  const [termDescription, setTermDescription] = useState<string>("");
  const [termInt, setTermInt] = useState<string>("0");
  const [targetTermDate, setTargetTermDate] = useState<any>();
  const [termStartDate, setTermStartDate] = useState<string>("");
  const [termEndDate, setTermEndDate] = useState<string>("");
  const [yearLevelId, setYearLevelId] = useState<string>("0");
  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    if (ApiDataResponseListLengthCheck(ApiDataListType.CALENDAR) == 0 ||
      calendarStore.calendarSettingList.data.length > PAGE_SIZE) {
      calendarStore.getCalendarSettingList(PAGE_SIZE, 0);
    }
    if (ApiDataResponseListLengthCheck(ApiDataListType.YEAR_LEVEL) == 0) {
      academicStore.getYearLevelList();
    }
  }, []);

  const onChangeYearLevel = (event: SelectChangeEvent) => {
    setYearLevelId(event.target.value as string);
  };

  const onRequestApiAction = (type: "year" | "semester" | "term" | "term date") => {
    let req: any = { id: 0 };
    switch (type) {
      case "year":
        req.startDate = yearStartDate;
        req.endDate = yearEndDate;
        req.yearDescription = yearDescription;
        req.collegeId = GetCollegeId();
        if (targetYear) {
          req.id = targetYear.id;
          calendarStore.updateSchoolYear(req).then(() => {
            rootStore.notify(t('SCHOOL_YEAR') + t('UPDATED_B'), 'success');
            onRefreshPage();
          }).catch(() => rootStore.notify(t('UPDATE_FAILED'), 'error'));
        } else {
          calendarStore.createSchoolYear(req).then(() => {
            rootStore.notify(t('SCHOOL_YEAR') + t('CREATED_B'), 'success')
            onRefreshPage();
          }).catch(() => rootStore.notify(t('CREATE_FAILED'), 'error'));
        }
        break;
      case "semester":
        req.semesterDescription = semesterDescription;
        req.semesterInt = +semesterInt;
        req.academicYearId = currentYearId;
        req.schoolTerms = [{
          termDescription: "",
          termInt: 1,
          schoolTermDates: [{
            yearLevelId: 1,
            startDate: termStartDate,
            endDate: termEndDate,
          }]
        }]

        if (targetSemester) {
          req.id = targetSemester.id;
          req.schoolTerms = targetSemester.schoolTerms;
          calendarStore.updateSchoolSemester(req).then(() => {
            rootStore.notify(t('SEMESTER') + t('UPDATED_B'), 'success');
            onRefreshPage();
          }).catch(() => rootStore.notify(t('UPDATE_FAILED'), 'error'));
        } else {
          calendarStore.createSchoolSemester(req).then(() => {
            rootStore.notify(t('SEMESTER') + t('CREATED_B'), 'success')
            onRefreshPage();
          }).catch(() => rootStore.notify(t('CREATE_FAILED'), 'error'))
        }
        break;
      case "term":
        req.termDescription = termDescription;
        req.termInt = termInt;
        req.semesterId = currentSemesterId;
        if (targetTerm) {
          req.id = targetTerm.id;
          calendarStore.updateSchoolTerm(req).then(() => {
            rootStore.notify(t('TERM') + t('UPDATED_B'), 'success');
            onRefreshPage();
          }).catch(() => rootStore.notify(t('UPDATE_FAILED'), 'error'))
        } else {
          calendarStore.createSchoolTerm(req).then(() => {
            rootStore.notify(t('TERM') + t('CREATED_B'), 'success')
            onRefreshPage();
          }).catch(() => rootStore.notify(t('CREATE_FAILED'), 'error'))
        }
        break;
      case "term date":
        req.startDate = termStartDate;
        req.endDate = termEndDate;
        req.yearLevelId = yearLevelId;
        req.termId = currentTermId;
        if (targetTermDate) {
          req.id = targetTermDate.id;
          calendarStore.updateSchoolTermDate(req).then(() => {
            rootStore.notify(t('TERM_DATE') + t('UPDATED_B'), 'success');
            onRefreshPage();
          }).catch(() => rootStore.notify(t('UPDATE_FAILED'), 'error'));
        } else {
          calendarStore.createSchoolTermDate(req).then(() => {
            rootStore.notify(t('TERM_DATE') + t('CREATED_B'), 'success')
            onRefreshPage();
          }).catch(() => rootStore.notify(t('CREATE_FAILED'), 'error'));
        }
        break;
    }
  }

  const handleShowModal = (id?: number) => {
    if (id) {
      let target = calendarStore.calendarSettingList.data.find(c => c.id == id);
      setTargetYear(target);
      setYearStartDate(target.startDate.split('T')[0]);
      setYearEndDate(target.endDate.split('T')[0]);
      setYearDescription(target.yearDescription);
    }
    setShowModal(true);
  }

  const handleShowSemesterModal = (id?: number) => {
    if (id) {
      let target = semesterList.find(s => s.id == id);
      setTargetSemester(target);
      setSemesterDescription(target.semesterDescription);
      setSemesterInt(target.semesterInt);
      setTermStartDate(target.schoolTerms[0].schoolTermDates[0].startDate.split('T')[0]);
      setTermEndDate(target.schoolTerms[0].schoolTermDates[0].endDate.split('T')[0]);
    }
    setShowSemesterModal(true);
  }

  const handleShowTermModal = (id?: number) => {
    if (id) {
      let target = termList.find(t => t.id == id);
      setTargetTerm(target);
      setTermDescription(target.termDescription);
      setTermInt(target.termInt);
    }
    setShowTermModal(true);
  }

  const handleShowTermDateModal = (id?: number) => {
    if (id) {
      let target = termList.find(t => t.id == currentTermId)
        .schoolTermDates.find(std => std.id == id);
      setTargetTermDate(target);
      setTermStartDate(target.startDate.split('T')[0]);
      setTermEndDate(target.endDate.split('T')[0]);
      setYearLevelId(target.yearLevelId);
    }
    setShowTermDateModal(true);
  }

  const handleCloseModal = () => {
    // modal
    setShowModal(false);
    setShowSemesterModal(false);
    setShowDeleteModal(false);
    setShowTermModal(false);
    setShowTermDateModal(false);
    // target
    setTargetYear(null);
    setTargetSemester(null);
    setTargetTerm(null);
    setTargetTermDate(null);
    // field
    setYearStartDate("");
    setYearEndDate("");
    setYearDescription("");
    setSemesterDescription("");
    setSemesterInt("0");
    setTermDescription("");
    setTermInt("0");
    setDeleteType("");
    setTermStartDate("");
    setTermEndDate("");
    setYearLevelId("0");
  }

  const handleDeleteModal = (id: number, type: "year" | "semester" | "term" | "term date") => {
    if (type === "year") {
      let target = calendarStore.calendarSettingList.data.find(c => c.id == id);
      setTargetYear(target);
    } else if (type === "semester") {
      let target = semesterList.find(s => s.id == id);
      setTargetSemester(target);
    } else if (type === "term") {
      let target = termList.find(t => t.id == id);
      setTargetTerm(target);
    } else {
      let target = termList.find(t => t.id == currentTermId).schoolTermDates.find(std => std.id == id);
      setTargetTermDate(target);
    }
    setDeleteType(type);
    setShowDeleteModal(true);
  }

  const onRefreshPage = () => {
    calendarStore.getCalendarSettingList(PAGE_SIZE, 0)
      .then((res) => {
        if (currentYearId != 0) {
          let tempSemList = res.data.find(y => y.id == currentYearId).schoolSemesters;
          setSemesterList(tempSemList);
          if (currentSemesterId != 0) {
            let tempTermList = tempSemList.find(s => s.id == currentSemesterId).schoolTerms;
            setTermList(tempTermList)
            if (currentTermId != 0) {
              setTermDateList(tempTermList.find(t => t.id == currentTermId).schoolTermDates);
            }
          }
        }
      });
    handleCloseModal();
  }

  return (
    <ContentLayout
      pageName={t('CALENDAR') + t('MODULE_B')}
      pageHeading={t('SCHOOL_YEAR')}
      breadCrumb={[
        { label: t('CALENDAR') + t('SETTING_B'), to: "" },
        { label: t('OVERVIEW'), to: "/calendar" }
      ]}
      buttonLabel={t('ADD_A') + t('SCHOOL_YEAR')}
      onClickAction={() => handleShowModal()}
    >
      <VmTable
        loading={calendarStore.loading}
        page={page}
        paginationCount={calendarStore.calendarSettingList.totalCount == 0 ? 1 : calendarStore.calendarSettingList.totalCount / PAGE_SIZE}
        onChangePagination={(event, pageNumber) => {
          setPage(pageNumber);
          setCurrentYearId(0);
          calendarStore.getCalendarSettingList(PAGE_SIZE, (pageNumber - 1) * PAGE_SIZE);
        }}
        thead={["ID", t('YEAR'), t('START_DATE'), t('END_DATE'), t('ACTION')]}
        tbody={calendarStore.calendarSettingList.data.length > 0 &&
          calendarStore.calendarSettingList.data.map((year, index) => (
            <>
              <Box marginY={1} />
              <tr key={`year_${index}`}>
                <td className={TD_FIRST}>{index + 1}</td>
                <td className={TD_NORMAL}>{year.yearDescription}</td>
                <td className={TD_NORMAL}>{year.startDate.split('T')[0]}</td>
                <td className={TD_NORMAL}>{year.endDate.split('T')[0]}</td>
                <td className={TD_LAST}>
                  <Button
                    variant="outlined"
                    sx={{ marginRight: 2 }}
                    onClick={() => handleShowModal(year.id)}
                  >
                    {t('EDIT')}
                  </Button>
                  <Button
                    variant="outlined"
                    sx={{ marginRight: 2 }}
                    onClick={() => {
                      setCurrentYearId(year.id);
                      setCurrentSemesterId(0);
                      setCurrentTermId(0);
                      setCurrentTermDateId(0);
                      setSemesterList(year.schoolSemesters);
                    }}
                  >
                    {t('VIEW_SEMESTERS')}
                  </Button>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => handleDeleteModal(year.id, "year")}
                  >
                    {t('DELETE')}
                  </Button>
                </td>
              </tr>
              <Box marginY={1} />
            </>
          ))}
      />

      {/* Semester */}
      {
        currentYearId != 0 &&
        <>
          <div className="flex justify-between mt-6">
            <Typography variant="h5" fontWeight={'bold'} marginBottom={2}
              sx={{ borderLeftWidth: 8, borderColor: 'black', height: 'fit-content', paddingLeft: 2 }}>
              {t('SCHOOL_SEMESTER')}: <span className="text-highlight">{calendarStore.calendarSettingList.data.find(c => c.id == currentYearId).yearDescription}</span>
            </Typography>
            <VmButton
              className="bg-highlight text-white px-4 py-2"
              style={{ height: 'fit-content' }}
              onClick={() => handleShowSemesterModal()}
            >
              {t('ADD_A') + t('SCHOOL_SEMESTER')}
            </VmButton>
          </div>
          <VmTable
            loading={calendarStore.loading}
            page={0}
            thead={["ID", t('SEMESTER') + t('DESCRIPTION_B'), t('START_DATE'), t('END_DATE'), t('ACTION')]}
            tbody={semesterList.length > 0 && semesterList
              .map((semester, index) => (
                <>
                  <Box marginY={1} />
                  <tr key={`semester_${index}`}>
                    <td className={TD_FIRST}>{index + 1}</td>
                    <td className={TD_NORMAL}>{semester.semesterDescription}</td>
                    <td className={TD_NORMAL}>
                      {semester.schoolTerms[0] &&
                        semester.schoolTerms[0].schoolTermDates[0] &&
                        semester.schoolTerms[0].schoolTermDates[0].startDate.split('T')[0]}
                    </td>
                    <td className={TD_NORMAL}>
                      {semester.schoolTerms[0] &&
                        semester.schoolTerms[0].schoolTermDates[0] &&
                        semester.schoolTerms[0].schoolTermDates[0].endDate.split('T')[0]}
                    </td>
                    <td className={TD_LAST}>
                      <Button
                        variant="outlined"
                        sx={{ marginRight: 2 }}
                        onClick={() => handleShowSemesterModal(semester.id)}
                      >
                        {t('EDIT')}
                      </Button>
                      {/* <Button
                        variant="outlined"
                        sx={{ marginRight: 2 }}
                        onClick={() => {
                          setCurrentSemesterId(semester.id);
                          setCurrentTermId(0);
                          setCurrentTermDateId(0);
                          setTermList(semester.schoolTerms);
                        }}
                      >
                        {t('VIEW_TERMS')}
                      </Button> */}
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => handleDeleteModal(semester.id, "semester")}
                      >
                        {t('DELETE')}
                      </Button>
                    </td>
                  </tr>
                  <Box marginY={1} />
                </>
              ))}
          />
        </>
      }

      {/* Term */}
      {/* {
        currentSemesterId != 0 &&
        <>
          <div className="flex justify-between mt-6">
            <Typography variant="h5" fontWeight={'bold'} marginBottom={2}
              sx={{ borderLeftWidth: 8, borderColor: 'black', height: 'fit-content', paddingLeft: 2 }}>
              {t('SCHOOL_TERM')}: <span className="text-highlight">{semesterList.find(s => s.id == currentSemesterId).semesterDescription}</span>
            </Typography>
            <VmButton
              className="bg-highlight text-white px-4 py-2"
              style={{ height: 'fit-content' }}
              onClick={() => handleShowTermModal()}
            >
              {t('ADD_A') + t('SCHOOL_TERM')}
            </VmButton>
          </div>
          <VmTable
            loading={calendarStore.loading}
            thead={["ID", t('TERM') + t('DESCRIPTION_B'), t('ACTION')]}
            tbody={termList.length > 0 && termList
              .map((term, index) => (
                <>
                  <Box marginY={1} />
                  <tr key={`term_${index}`}>
                    <td className={TD_FIRST}>{index + 1}</td>
                    <td className={TD_NORMAL}>{term.termDescription}</td>
                    <td className={TD_LAST}>
                      <Button
                        variant="outlined"
                        sx={{ marginRight: 2 }}
                        onClick={() => handleShowTermModal(term.id)}
                      >
                        {t('EDIT')}
                      </Button>
                      <Button
                        variant="outlined"
                        sx={{ marginRight: 2 }}
                        onClick={() => {
                          setCurrentTermId(term.id);
                          setCurrentTermDateId(0);
                          setTermDateList(term.schoolTermDates);
                        }}
                      >
                        {t('VIEW_TERM_DATE')}
                      </Button>
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => handleDeleteModal(term.id, "term")}
                      >
                        {t('DELETE')}
                      </Button>
                    </td>
                  </tr>
                  <Box marginY={1} />
                </>
              ))}
          />
        </>
      } */}

      {/* Term Date */}
      {/* {
        currentTermId != 0 &&
        <>
          <div className="flex justify-between mt-6">
            <Typography variant="h5" fontWeight={'bold'} marginBottom={2}
              sx={{ borderLeftWidth: 8, borderColor: 'black', height: 'fit-content', paddingLeft: 2 }}>
              {t('SCHOOL_TERM_DATES')}: <span className="text-highlight">{termList.find(s => s.id == currentTermId).termDescription}</span>
            </Typography>
            <VmButton
              className="bg-highlight text-white px-4 py-2"
              style={{ height: 'fit-content' }}
              onClick={() => handleShowTermDateModal()}
            >
              {t('ADD_A') + t('TERM_DATE')}
            </VmButton>
          </div>
          <VmTable
            loading={calendarStore.loading}
            page={0}
            thead={["ID", t('START_DATE'), t('END_DATE'), t('ACTION')]}
            tbody={termDateList.length > 0 && termDateList
              .map((termDate, index) => (
                <>
                  <Box marginY={1} />
                  <tr key={`TermDate_${index}`}>
                    <td className={TD_FIRST}>{index + 1}</td>
                    <td className={TD_NORMAL}>{termDate.startDate.split('T')[0]}</td>
                    <td className={TD_NORMAL}>{termDate.endDate.split('T')[0]}</td>
                    <td className={TD_LAST}>
                      <Button
                        variant="outlined"
                        sx={{ marginRight: 2 }}
                        onClick={() => handleShowTermDateModal(termDate.id)}
                      >
                        {t('EDIT')}
                      </Button>
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => handleDeleteModal(termDate.id, "term date")}
                      >
                        {t('DELETE')}
                      </Button>
                    </td>
                  </tr>
                  <Box marginY={1} />
                </>
              ))}
          />
        </>
      } */}

      {/* Year Modal */}
      <VmModal
        openModal={showModal}
        onClose={handleCloseModal}
        title={targetYear ? t('UPDATE_A') + t('YEAR') : t('ADD_A') + t('YEAR')}
        buttonLabel={targetYear ? t('CONFIRM_A') + t('UPDATE') : t('CONFIRM_A') + t('CREATION')}
        buttonLabelWithoutConfirm={targetYear ? t('UPDATE_NOW') : t('CREATE_NOW')}
        onClickConfirmedButton={() => onRequestApiAction("year")}
        disabledCondition={yearDescription === "" || yearStartDate === "" || yearEndDate === ""}
      >
        <TextField
          value={yearDescription}
          label={t('YEAR')}
          variant="outlined"
          sx={{ width: '100%', marginBottom: 2 }}
          onChange={(value) => setYearDescription(value.target.value)}
        />
        <TextField
          value={yearStartDate}
          label={t('START_DATE')}
          type="date"
          variant="outlined"
          sx={{ width: '100%', marginBottom: 2 }}
          onChange={(value) => setYearStartDate(value.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          value={yearEndDate}
          label={t('END_DATE')}
          type="date"
          variant="outlined"
          sx={{ width: '100%', marginBottom: 2 }}
          onChange={(value) => setYearEndDate(value.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </VmModal>

      {/* Semester Modal */}
      <VmModal
        openModal={showSemesterModal}
        onClose={handleCloseModal}
        title={targetSemester ? t('UPDATE_A') + t('SEMESTER') : t('ADD_A') + t('SEMESTER')}
        buttonLabel={targetSemester ? t('CONFIRM_A') + t('UPDATE') : t('CONFIRM_A') + t('CREATION')}
        buttonLabelWithoutConfirm={targetSemester ? t('UPDATE_NOW') : t('CREATE_NOW')}
        onClickConfirmedButton={() => onRequestApiAction("semester")}
        disabledCondition={semesterDescription === "" || semesterInt === "" || +semesterInt == 0
          || termStartDate === "" || termEndDate === ""}
        width={450}
      >
        <div className="grid grid-cols-2 gap-4">
          <TextField
            value={semesterDescription}
            label={t('SEMESTER') + t('DESCRIPTION_B')}
            variant="outlined"
            sx={{ width: '100%' }}
            onChange={(value) => setSemesterDescription(value.target.value)}
          />
          <TextField
            value={semesterInt}
            label={t('SEMESTER_INT') + " (1, 2, 3...)"}
            variant="outlined"
            sx={{ width: '100%' }}
            type="number"
            inputProps={{ maxLength: 1 }}
            onChange={(value) => setSemesterInt(value.target.value)}
          />
          <TextField
            value={termStartDate}
            label={t('START_DATE')}
            type="date"
            variant="outlined"
            sx={{ width: '100%' }}
            onChange={(value) => setTermStartDate(value.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            value={termEndDate}
            label={t('END_DATE')}
            type="date"
            variant="outlined"
            sx={{ width: '100%' }}
            onChange={(value) => setTermEndDate(value.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        {/* <FormControl fullWidth>
          <InputLabel>{t('YEAR_LEVEL')}</InputLabel>
          {
            academicStore.yearLevelList.length > 0 &&
            <Select
              label={t('YEAR_LEVEL')}
              sx={{ width: '100%' }}
              value={yearLevelId}
              onChange={onChangeYearLevel}
            >
              <MenuItem value={"0"}>{t('NOT_SELECTED')}</MenuItem>
              {academicStore.yearLevelList.map((yl: any, index: number) => (
                <MenuItem key={`year_level_select_${index}`} value={yl.id}>
                  {yl.yearLevelDescription}
                </MenuItem>
              ))}
            </Select>
          }
        </FormControl> */}
      </VmModal>

      {/* Term Modal */}
      <VmModal
        openModal={showTermModal}
        onClose={handleCloseModal}
        title={targetTerm ? t('UPDATE_A') + t('TERM') : t('ADD_A') + t('TERM')}
        buttonLabel={targetTerm ? t('CONFIRM_A') + t('UPDATE') : t('CONFIRM_A') + t('CREATION')}
        buttonLabelWithoutConfirm={targetTerm ? t('UPDATE_NOW') : t('CREATE_NOW')}
        onClickConfirmedButton={() => onRequestApiAction("term")}
        disabledCondition={termDescription === "" || termInt === "" || termInt === "0"}
      >
        <TextField
          value={termDescription}
          label={t('TERM')}
          variant="outlined"
          sx={{ width: '100%', marginBottom: 2 }}
          onChange={(value) => setTermDescription(value.target.value)}
        />
        <TextField
          value={termInt}
          label={t('TERM_INT')}
          variant="outlined"
          sx={{ width: '100%', marginBottom: 2 }}
          type="number"
          inputProps={{ maxLength: 1 }}
          onChange={(value) => setTermInt(value.target.value)}
        />
      </VmModal>

      {/* Term Date Modal */}
      <VmModal
        openModal={showTermDateModal}
        onClose={handleCloseModal}
        title={targetTermDate ? t('UPDATE_A') + t('TERM_DATE') : t('ADD_A') + t('TERM_DATE')}
        buttonLabel={targetTermDate ? t('CONFIRM_A') + t('UPDATE') : t('CONFIRM_A') + t('CREATION')}
        buttonLabelWithoutConfirm={targetTermDate ? t('UPDATE_NOW') : t('CREATE_NOW')}
        onClickConfirmedButton={() => onRequestApiAction("term date")}
        disabledCondition={academicStore.yearLevelList.length < 1 ||
          termStartDate === "" || termEndDate === "" || yearLevelId === "0"}
      >
        <TextField
          value={termStartDate}
          label={t('START_DATE')}
          type="date"
          variant="outlined"
          sx={{ width: '100%', marginBottom: 2 }}
          onChange={(value) => setTermStartDate(value.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          value={termEndDate}
          label={t('END_DATE')}
          type="date"
          variant="outlined"
          sx={{ width: '100%', marginBottom: 2 }}
          onChange={(value) => setTermEndDate(value.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <FormControl fullWidth>
          <InputLabel>{t('YEAR_LEVEL')}</InputLabel>
          {
            academicStore.yearLevelList.length > 0 &&
            <Select
              label={t('YEAR_LEVEL')}
              sx={{ width: '100%', marginBottom: 2 }}
              value={yearLevelId}
              onChange={onChangeYearLevel}
            >
              <MenuItem value={"0"}>{t('NOT_SELECTED')}</MenuItem>
              {academicStore.yearLevelList.map((yl: any, index: number) => (
                <MenuItem key={`year_level_select_${index}`} value={yl.id}>
                  {yl.yearLevelDescription}
                </MenuItem>
              ))}
            </Select>
          }
        </FormControl>
      </VmModal>


      <VmModal
        openModal={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        title={t('DELETE_A') + t('CONFIRMATION')}
        buttonLabel={t('CONFIRM_DELETE')}
        buttonLabelWithoutConfirm={t('DELETE_NOW')}
        onClickConfirmedButton={() => {
          if (deleteType === "year") {
            calendarStore.deleteSchoolYear(targetYear.id).then(() => {
              rootStore.notify(t('SCHOOL_YEAR') + t('DELETED_B'), 'success');
              onRefreshPage();
            }).catch(() => rootStore.notify(t('DELETE_FAILED'), 'error'));
          } else if (deleteType === "semester") {
            calendarStore.deleteSchoolSemester(targetSemester.id).then(() => {
              rootStore.notify(t('SEMESTER') + t('DELETED_B'), 'success');
              onRefreshPage();
            }).catch(() => rootStore.notify(t('DELETE_FAILED'), 'error'));
          } else if (deleteType === "term") {
            calendarStore.deleteSchoolTerm(targetTerm.id).then(() => {
              rootStore.notify(t('TERM') + t('DELETED_B'), 'success');
              onRefreshPage();
            }).catch(() => rootStore.notify(t('DELETE_FAILED'), 'error'));
          } else if (deleteType === "term date") {
            calendarStore.deleteSchoolSemester(targetTermDate.id).then(() => {
              rootStore.notify(t('TERM_DATE') + t('DELETED_B'), 'success')
              onRefreshPage();
            }).catch(() => rootStore.notify(t('DELETE_FAILED'), 'error'));
          }
        }}
      >
        <Typography sx={{ marginBottom: 2 }}>
          {t('DELETE_CHECK')}: {t(deleteType.toUpperCase().replace(' ', '_'))}?
        </Typography>
        <Typography color="red" variant="caption">
          {t('DELETE_INFO_EXAM')}
        </Typography>
      </VmModal>
    </ContentLayout>
  )
});

export default CalendarIndexPage;
